import React, { useState, useEffect, useRef } from "react"
import { Link } from "gatsby"
import { useIntl } from "gatsby-plugin-intl"

import Button from "../../../../newUikit/Button"
import Search from "../../../Search"
import ThemeSwitch from "../../../ThemeSwitch"
import Footer from "../../../Footer"
import { getScrollbarWidth } from "../../../../uikit/Modal/utils"

import DashaLogo from "../../assets/dasha-logo.inline.svg"
import DashaLogoWhite from "../../assets/dasha-logo-white.inline.svg"
import TwitterLogo from "../../assets/twitter-logo.inline.svg"
import GitHubLogo from "../../assets/github-logo.inline.svg"
import MenuIcon from "../../assets/menu.inline.svg"
import CloseIcon from "../../assets/close.inline.svg"
import skBlackLogoSrc from "../../assets/sk-black.png"
import skWhiteLogoSrc from "../../assets/sk-white.png"

import * as S from "./styled"

const MobileHeader = ({
  theme,
  isSearchFocused,
  footerContent,
  content,
  setIsSearchFocused,
  openModal,
  changeTheme,
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [navOffset, setNavOffset] = useState(0)
  const menuEl = useRef(null)
  const { locale } = useIntl()
  const isRu = locale === "ru-ru"

  const handleClick = () => {
    setIsMenuOpen(prevValue => !prevValue)
  }

  useEffect(() => {
    if (isMenuOpen) {
      const bannerEl = document.getElementById("top-banner")

      if (!bannerEl || bannerEl.clientHeight <= window.pageYOffset) {
        setNavOffset(0)
        return
      }

      setNavOffset(bannerEl.clientHeight - window.pageYOffset)
    }
  }, [isMenuOpen, setNavOffset])

  useEffect(() => {
    const headerEl = document.getElementById("header")
    const scrollWidth = getScrollbarWidth()

    if (isMenuOpen) {
      headerEl.style.width = `calc(100% - ${scrollWidth}px)`
      document.body.style.paddingRight = `${scrollWidth}px`
      document.body.style.overflow = "hidden"
    } else {
      headerEl.style.width = "100%"
      document.body.style.paddingRight = "0"
      document.body.style.overflow = "visible"
    }

    return () => {
      document.body.style.paddingRight = "0"
      headerEl.style.width = "100%"
    }
  }, [isMenuOpen])

  return (
    <S.Container className="inner">
      <S.LogoContainer>
        <S.MenuBtn isRu={isRu} title="Menu" onClick={handleClick}>
          {isMenuOpen ? <CloseIcon /> : <MenuIcon />}
        </S.MenuBtn>
        <Link to={content.logoLink}>
          {theme === "dark" ? <DashaLogoWhite /> : <DashaLogo />}
        </Link>
      </S.LogoContainer>
      <S.Nav>
        <Search theme={theme} changeIsFocused={setIsSearchFocused} />
        <S.Box searchFocused={isSearchFocused ? 1 : 0}>
          {!isRu && (
            <>
              {content.links.map(item =>
                item.link.includes("http") ? (
                  <S.Link
                    key={item.link}
                    id="Documentation"
                    href={item.link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {item.label}
                  </S.Link>
                ) : (
                  <S.InnerLink key={item.link} to={item.link}>
                    {item.label}
                  </S.InnerLink>
                )
              )}
              <S.SocIcons>
                <S.SocLink
                  id="Twitter"
                  href={content.twitterLink}
                  target="_blank"
                >
                  <TwitterLogo />
                </S.SocLink>
                <S.SocLink
                  id="Github"
                  href={content.gitHubLink}
                  target="_blank"
                >
                  <GitHubLogo />
                </S.SocLink>
              </S.SocIcons>
            </>
          )}

          <S.ButtonsContainer isRu={isRu}>
            <Button size="sm" url={content.signUpLink} target="_blank">
              {content.signUp}
            </Button>
            <Button id="ContactSales" size="sm" inverse onClick={openModal}>
              {content.contactSales}
            </Button>
          </S.ButtonsContainer>
          {changeTheme && (
            <ThemeSwitch
              currentTheme={theme}
              onChange={changeTheme}
            />
          )}
          {isRu && (
            <S.SkolkovoLogo>
              <a href="https://sk.ru/" target="_blank" rel="noreferrer">
                <img className="sk-black" src={skBlackLogoSrc} alt="Skolkovo" />
                <img className="sk-white" src={skWhiteLogoSrc} alt="Skolkovo" />
              </a>
            </S.SkolkovoLogo>
          )}
        </S.Box>
      </S.Nav>
      <S.HiddenMenu $offset={navOffset} $isOpened={isMenuOpen}>
        <nav ref={menuEl}>
          {isRu && (
            <S.SkolkovoContainer>
              <S.SkolkovoLogo>
                <a href="https://sk.ru/" target="_blank" rel="noreferrer">
                  <img
                    className="sk-black"
                    src={skBlackLogoSrc}
                    alt="Skolkovo"
                  />
                  <img
                    className="sk-white"
                    src={skWhiteLogoSrc}
                    alt="Skolkovo"
                  />
                </a>
              </S.SkolkovoLogo>
              <S.ButtonsContainer isRu={isRu}>
                <Button
                  className="btn"
                  size="sm"
                  url={content.signUpLink}
                  target="_blank"
                >
                  {content.signUp}
                </Button>
                <Button
                  className="btn"
                  id="ContactSales"
                  size="sm"
                  inverse
                  onClick={openModal}
                >
                  {content.contactSales}
                </Button>
              </S.ButtonsContainer>
            </S.SkolkovoContainer>
          )}

          <Footer
            content={{ ...footerContent, header: content }}
            isMobileMenu
            openModal={openModal}
          />
        </nav>
        <S.Overlay onClick={handleClick}></S.Overlay>
      </S.HiddenMenu>
    </S.Container>
  )
}

export default MobileHeader
