import React, { useState } from "react"
import { useIntl } from "gatsby-plugin-intl"

import { useBreakpoint } from "../../common/hooks"

import DesktopHeader from "./components/DesktopHeader"
import MobileHeader from "./components/MobileHeader"
import * as S from "./styled"

const Header = ({ content, theme, openModal, changeTheme }) => {
  const [isSearchFocused, setIsSearchFocused] = useState(false)
  const isMobile = useBreakpoint(1199)
  const { locale } = useIntl()
  
  // Fix the links to follow the new URL pattern
  const headerContent = {
    ...content.header,
    links: content.header.links.map(link => {
      // Only process internal links (non-http)
      if (link.link && !link.link.includes('http')) {
        // For blog links, ensure we use the right format based on locale
        if (link.link.includes('/blog')) {
          return {
            ...link,
            link: locale === 'ru-ru' && !link.link.startsWith('/ru-ru') ? `/ru-ru${link.link}` : link.link
          };
        }
      }
      return link;
    }),
    // Also update mobile links
    mobileLinks: content.header.mobileLinks?.map(link => {
      // Only process internal links (non-http)
      if (link.href && !link.href.includes('http')) {
        // For blog links, ensure we use the right format based on locale
        if (link.href.includes('/blog')) {
          return {
            ...link,
            href: locale === 'ru-ru' && !link.href.startsWith('/ru-ru') ? `/ru-ru${link.href}` : link.href
          };
        }
      }
      return link;
    })
  };

  const headerProps = {
    theme,
    content: headerContent,
    openModal,
    changeTheme,
    isSearchFocused,
    setIsSearchFocused,
  }

  return (
    <>
      <S.Container id="header">
        {isMobile ? (
          <MobileHeader {...headerProps} footerContent={content.footer} />
        ) : (
          <DesktopHeader {...headerProps} />
        )}
      </S.Container>
    </>
  )
}

export default Header
