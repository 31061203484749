import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { useLocale } from "./common/hooks";

function SEO({ description = "", title = "", keywords = "", location = {}, alternateLanguages = {} }) {
  const locale = useLocale();
  const isRus = locale.includes("ru");
  
  const seoPostfix = "Dasha.AI";
  const image = "/thumbnail.png";

  const metaDescription = description;
  const metaTitle = title || seoPostfix;
  const metaKeywords = keywords;
  
  // Get the path without query parameters for canonical URLs
  const pathname = location.pathname || "";
  const pagePath = pathname.split('?')[0];
  const siteUrl = process.env.SITE_URL || "https://dasha.ai";
  
  // Determine if this is a Russian page
  const isRussianPage = pathname.startsWith("/ru-ru");
  
  // Create canonical URL based on locale
  const canonicalUrl = isRussianPage
    ? `${siteUrl}/ru-ru${pagePath.replace(/^\/ru-ru/, '')}`
    : `${siteUrl}${pagePath.replace(/^\/(en|en-us)/, '')}`;
  
  // Create hreflang URLs
  const englishUrl = `${siteUrl}${pagePath.replace(/^\/ru-ru/, '').replace(/^\/(en|en-us)/, '')}`;
  const russianUrl = `${siteUrl}/ru-ru${pagePath.replace(/^\/ru-ru/, '').replace(/^\/(en|en-us)/, '')}`;

  // Determine which hreflang tags to include based on available content
  const hasEnglishVersion = alternateLanguages.hasEnglish !== false; // Default to true if not specified
  const hasRussianVersion = alternateLanguages.hasRussian !== false; // Default to true if not specified

  // Create hreflang link elements only for languages that exist
  const hreflangTags = [];
  
  if (hasEnglishVersion) {
    // English version is available - add it and x-default
    hreflangTags.push(<link key="hreflang-en" rel="alternate" hrefLang="en" href={englishUrl} />);
    hreflangTags.push(<link key="hreflang-default" rel="alternate" hrefLang="x-default" href={englishUrl} />);
  }
  
  if (hasRussianVersion) {
    // Russian version is available
    hreflangTags.push(<link key="hreflang-ru-ru" rel="alternate" hrefLang="ru-ru" href={russianUrl} />);
  }

  return (
    <Helmet
      htmlAttributes={{
        lang: locale,
      }}
      title={metaTitle}
      titleTemplate={metaTitle ? `${seoPostfix} | ${metaTitle}` : seoPostfix}
      meta={[
        {
          name: "keywords",
          content: metaKeywords,
        },
        {
          name: "description",
          content: metaDescription,
        },
        {
          name: "twitter:description",
          content: metaDescription,
        },
        {
          name: "twitter:card",
          content: "summary_large_image",
        },
        {
          property: "twitter:card",
          content: "summary_large_image",
        },
        {
          property: "og:description",
          content: metaDescription,
        },
        {
          name: "image",
          content: image,
        },
        {
          property: "og:title",
          content: metaTitle,
        },
        {
          property: "og:type",
          content: "website",
        },
        {
          property: "og:image",
          content: image,
        },
        {
          property: "og:image:width",
          content: "512",
        },
        {
          property: "og:image:height",
          content: "512",
        },
        {
          name: "twitter:card",
          content: "summary",
        },
        {
          name: "twitter:creator",
          content: "dasha.ai",
        },
        {
          name: "twitter:title",
          content: metaTitle,
        },
      ]}
    >
      {/* Canonical URL */}
      <link rel="canonical" href={canonicalUrl} />
      
      {/* hreflang tags - only add for languages that exist */}
      {hreflangTags}

      <link
        rel="alternate"
        type="application/rss+xml"
        title="RSS Dasha Blog"
        href={isRus ? `/ru-ru/feed.xml` : `/feed.xml`}
      />

      {/* Google Optimize */}
      <script src="https://www.googleoptimize.com/optimize.js?id=OPT-NV6S949"></script>

      {/* Load Segment script */}
      <script src="/segment.js" />

      {/* Google Tag Manager initialization */}
      <script>
        {`
          document.addEventListener('DOMContentLoaded', () => {
            /** Initialize GTM after a delay */
            setTimeout(initGTM, 3500);
          });
          document.addEventListener('scroll', initGTMOnEvent);
          document.addEventListener('mousemove', initGTMOnEvent);
          document.addEventListener('touchstart', initGTMOnEvent);
          
          function initGTMOnEvent(event) {
            initGTM();
            event.currentTarget.removeEventListener(event.type, initGTMOnEvent);
          }
          
          function initGTM() {
            if (window.gtmDidInit) {
              return false;
            }
            window.gtmDidInit = true;
            const script = document.createElement('script');
            script.type = 'text/javascript';
            script.async = true;
            script.onload = () => {
              dataLayer.push({
                event: 'gtm.js',
                'gtm.start': new Date().getTime(),
                'gtm.uniqueEventId': 0,
              });
            };
            script.src = 'https://www.googletagmanager.com/gtm.js?id=${
              isRus ? 'G-MZ67EJSKT5' : 'GTM-N7TF5X7'
            }';
            document.head.appendChild(script);
          }
        `}
      </script>
    </Helmet>
  );
}

SEO.defaultProps = {
  lang: "en",
  meta: [],
  description: "",
  image: null,
  location: {},
  alternateLanguages: {}, // Default to assume all languages exist
};

SEO.propTypes = {
  description: PropTypes.string,
  title: PropTypes.string,
  keywords: PropTypes.string,
  location: PropTypes.object,
  alternateLanguages: PropTypes.object, // Add new prop type for language availability
};

export default SEO;
