/* eslint-disable react/display-name */

import React from "react"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { MARKS, BLOCKS, INLINES } from "@contentful/rich-text-types"
import { useIntl } from "gatsby-plugin-intl"
import { useLocale } from "src/common/hooks"
import get from "lodash/get"
import AudioPlayer from "../../../../components/HomePage/Features/components/AudioPlayer"

import * as S from "./styled"
import {
  DOWNLOAD_ASSET_MODAL,
  getModalClickHandler,
} from "src/components/ModalsContainer/events"

const renderLink = (node, children) => {
  const { locale } = useIntl()

  if (node.data.uri.indexOf("mailto") !== -1) {
    return (
      <S.Link href={node.data.uri} target="_blank" rel="noopener noreferrer">
        {children}
      </S.Link>
    )
  }

  if (node.data.uri.indexOf("http") === -1) {
    // Generate URL based on the new pattern - only prefix Russian links with /ru-ru
    const innerUrl = locale === "ru-ru"
      ? `/ru-ru${node.data.uri}`
      : `${node.data.uri}`
      
    return (
      <S.InnerLink to={innerUrl}>{children}</S.InnerLink>
    )
  } else {
    return (
      <S.Link href={node.data.uri} target="_blank" rel="noopener noreferrer">
        {children}
      </S.Link>
    )
  }
}

const options = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => {
      return <S.Paragraph>{children}</S.Paragraph>
    },
    [BLOCKS.HEADING_1]: (node, children) => (
      <S.Title as="h1">{children}</S.Title>
    ),
    [BLOCKS.HEADING_2]: (node, children) => {
      return <S.Title as="h2">{children}</S.Title>
    },
    [BLOCKS.HEADING_3]: (node, children) => (
      <S.Title as="h3">{children}</S.Title>
    ),
    [BLOCKS.HEADING_4]: (node, children) => (
      <S.Title as="h4">{children}</S.Title>
    ),
    [BLOCKS.HEADING_5]: (node, children) => (
      <S.Title as="h5">{children}</S.Title>
    ),
    [BLOCKS.HEADING_6]: (node, children) => (
      <S.Title as="h6">{children}</S.Title>
    ),
    [BLOCKS.UL_LIST]: (node, children) => (
      <S.UnorderedList>{children}</S.UnorderedList>
    ),
    [BLOCKS.OL_LIST]: (node, children) => (
      <S.OrderedList>{children}</S.OrderedList>
    ),
    [BLOCKS.LIST_ITEM]: (node, children) => (
      <S.ListItem>
        <span className="circle"></span>
        {children}
      </S.ListItem>
    ),
    [BLOCKS.EMBEDDED_ENTRY]: node => {
      const locale = useLocale()

      if (node?.data?.target?.sys?.contentType?.sys?.id === "codeBlock") {
        return (
          <S.Code>
            <pre>{node?.data?.target?.fields?.codeBlock[locale]}</pre>
          </S.Code>
        )
      }

      return null
    },
    [BLOCKS.EMBEDDED_ASSET]: node => {
      const locale = useLocale()
      const { fields } = node.data.target

      const src = get(fields, `file.${locale}.url`, null)
      const title = get(fields, `title.${locale}`, null)

      return (
        <S.Asset>
          <img className="lazyload" data-src={src} alt={title} />
          <figcaption>{title}</figcaption>
        </S.Asset>
      )
    },
    [BLOCKS.QUOTE]: (node, children) => <S.Quote>{children}</S.Quote>,
    [INLINES.HYPERLINK]: (node, children) => renderLink(node, children),
    [INLINES.ENTRY_HYPERLINK]: (node, children) => {
      const locale = useLocale()
      const { locale: intlLocale } = useIntl()
      const { fields, sys } = node.data.target

      const contentType = sys.contentType.sys.contentful_id

      if (contentType === "blogPost") {
        let uri = fields.blogPostId
          ? `/blog/${fields.blogPostId[locale]}`
          : "/"
          
        // Add /ru-ru prefix only for Russian locale
        if (intlLocale === "ru-ru") {
          uri = `/ru-ru${uri}`
        }

        return renderLink({ data: { uri } }, children)
      }

      if (contentType === "commonDownloadAssetModal") {
        const handleClick = e => {
          e.preventDefault()

          const handleShowDownloadAssetModal = getModalClickHandler(
            DOWNLOAD_ASSET_MODAL,
            {
              modalId: fields.modalId["en-US"],
            }
          )

          handleShowDownloadAssetModal()
        }

        return (
          <S.InnerLink onClick={handleClick} to="#">
            {children}
          </S.InnerLink>
        )
      }

      return children
    },
  },
  renderMark: {
    [MARKS.CODE]: text => <S.Code>{text}</S.Code>,
  },
}

const renderLinkMd = props => {
  const { children, href, ...rest } = props

  if (
    children.includes !== undefined &&
    children.includes("Embedded content")
  ) {
    return (
      <a href={href} {...rest}>
        {children}
      </a>
    )
  }

  if (href.indexOf("mailto") !== -1) {
    return (
      <S.Link href={href} target="_blank" rel="noopener noreferrer">
        {children}
      </S.Link>
    )
  }

  return href.indexOf("http") === -1 ? (
    <S.InnerLink to={`/${href}`}>{children}</S.InnerLink>
  ) : (
    <S.Link href={href} target="_blank" rel="noopener noreferrer">
      {children}
    </S.Link>
  )
}

export const mdOptions = {
  a: renderLinkMd,
  p: ({ children }) => <S.Paragraph>{children}</S.Paragraph>,
  h1: ({ children }) => <S.Title as="h1">{children}</S.Title>,
  h2: ({ children }) => <S.Title as="h2">{children}</S.Title>,
  h3: ({ children }) => <S.Title as="h3">{children}</S.Title>,
  h4: ({ children }) => <S.Title as="h4">{children}</S.Title>,
  h5: ({ children }) => <S.Title as="h5">{children}</S.Title>,
  h6: ({ children }) => <S.Title as="h6">{children}</S.Title>,
  code: ({ children }) => <S.Code>{children}</S.Code>,
  inlineCode: ({ children }) => <S.Code>{children}</S.Code>,
  quote: ({ children }) => <S.Quote>{children}</S.Quote>,
  img: ({ src, title, alt }) => {
    if (src.includes(".mp3")) {
      return (
        <S.Audio>
          <AudioPlayer name={alt} src={src} />
        </S.Audio>
      )
    }

    return (
      <S.Asset>
        <img className="lazyload" data-src={src} alt={title} />
        <figcaption>{title}</figcaption>
      </S.Asset>
    )
  },
  ul: ({ children }) => <S.UnorderedList>{children}</S.UnorderedList>,
  ol: ({ children }) => <S.OrderedList>{children}</S.OrderedList>,
  li: ({ children }) => (
    <S.ListItem>
      <span className="circle"></span>
      {children}
    </S.ListItem>
  ),
}

const Content = ({ json }) => {
  return documentToReactComponents(json, options)
}

export default Content
